import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import "../stylesheets/philosophy.scss"

const PhilosophyPage = () => (
  <Layout>
    <SEO
      title="企業理念"
      description="道新インタラクティブ株式会社の企業理念です。道新インタラクティブ株式会社は、人と企業を結ぶ懸け橋となり、地域の発展に貢献することを目指します。"
    />
    <div className="philosophyPage-MainVisual">
      <div className="philosophyPage-MainVisual-headingWrapper">
        <h1 className="philosophyPage-MainVisual-heading">企業理念</h1>
        <p className="philosophyPage-MainVisual-subHeading">Philosophy</p>
      </div>
    </div>
    <section className="philosophyPage-MainSection">
      <div className="philosophyPage-MainSection-inner">
        <h2 className="philosophyPage-MainSection-heading">
          人と企業を結ぶ懸け橋となり、
          <br />
          地域の発展に貢献する。
        </h2>
        <div className="philosophyPage-MainSection-contentsWrapper">
          <div className="philosophyPage-MainSection-imageArea">
            <div className="philosophyPage-MainSection-imageWrapper">
              <StaticImage
                src="../images/ceo.jpg"
                alt=""
                className="philosophyPage-MainSection-image"
              />
            </div>
            <div className="philosophyPage-MainSection-nameArea">
              <p className="philosophyPage-MainSection-position">
                代表取締役社長
              </p>
              <p className="philosophyPage-MainSection-name">沼田昌広</p>
            </div>
          </div>
          <div className="philosophyPage-MainSection-textArea">
            <p className="philosophyPage-MainSection-text">
              自然豊かな北の大地で暮らし、やりがいのある仕事に打ち込みながら、自分の生活を大事にしたいという方々が増えています。
              <br />
              <br />
              北海道で働きたい方と、働いてもらいたい北海道の会社をつなげる仕事を通じて、地域に貢献していきたい−。「北海道で働こう」キャンペーンを展開する北海道新聞社と、地域への思いを日本の最南端で形にしてきた沖縄のIT企業琉球インタラクティブが心を一つにし、「道新インタラクティブ株式会社」が誕生しました。
              <br />
              <br />
              私たちは「地域を盛り上げる最重要資源は人材であり、北海道と沖縄の地元企業の最大ニーズ」、「地域の課題を解決するためにテクノロジーの活用は不可欠」との理念を共有し、合弁企業としてスタートいたします。
              <br />
              <br />
              仕事を探す主な媒体は新聞から求人雑誌、ウェブサイトへとうつり変わり、情報も、企業から発信するだけではなく、オンラインで求人側と求職者が双方向でやりとりする形へと変化しつつあります。
              <br />
              <br />
              この潮流をしっかりと捉え、北海道で働きたい人たちのキャリアづくりのお手伝いとともに、北海道の企業・団体が、それぞれの組織を支える人たちと出会えるよう、誠心誠意、力を尽くしてまいります。
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PhilosophyPage
